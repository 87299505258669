import React from "react";

const Price = ({spacialPrice, finalPrice}) => {
  return (
    <>
      {spacialPrice ? (
        <div className="price">
          <span>&#8377; {parseFloat(finalPrice).toFixed(2)}</span>
          &#8377; {parseFloat(spacialPrice).toFixed(2)}
        </div>
      ) : (
        <div className="price">
          &#8377; {parseFloat(finalPrice).toFixed(2)}
        </div>
      )}
    </>
  );
};

export default Price;
